import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { useDispatch, useSelector } from 'react-redux'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userBusinessListRequest, userBusinessListRequestSuccess } from '../../../../redux/business/business.action'
import { useNavigate } from 'react-router-dom';
import { ROUTES, API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import moment from "moment";
import TopButton from "../../../../components/button/top-button/topButton";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";

const BusinessListScreen = props => {
    const { user } = useSelector(state => state.user)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'BUSINESS_LIST',
            screenClass: 'BUSINESS_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        fetchBusinessList()
    }, [])

    const fetchBusinessList = () => {
        setLoading(true)
        const data1 = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data1))
    }

    useEffect(() => {
        if (!isNull(userBusinessListRequestSuccess)) {
            setLoading(false)
        } else if (!isNull(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    const handleCardClick = (element) => {
        navigate(ROUTES.businessDetail, { state: element.businessId })
    }

    const addBusinessClickHandle = () => {
        navigate(ROUTES.addBusiness)
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='mt-2'>
                    <ScreenLabel label='Business' />
                    <TopButton label='Add Business'
                        onClick={() => addBusinessClickHandle()} />
                </CommonInputRow>
                <div className="table-scroll-container">
                    {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <table class="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userBusinessListSuccess.data.map(element => (
                                <tr className="rl-table-row-container" onClick={() => { handleCardClick(element) }}>
                                    <td className="rl-table-col-item align-middle">{element.businessName}</td>
                                    <td className={`rl-table-col-item text-center align-middle ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center align-middle">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="loc-table-col-item">
                                        <div className="loc-list-button-container">
                                            <div className="loc-list-button" onClick={() => { handleCardClick(element) }}>Edit</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default BusinessListScreen