import { OfferActionTypes } from './offer.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addOfferSuccess: null,
    addOfferError: null,
    addOfferReqStatus: '',
    offerListSuccess: null,
    offerListError: null,
    offerDetailSuccess: null,
    offerDetailError: null,
    updateStatusOfferSuccess: null,
    updateStatusOfferError: null,
    updateStatusOfferReqStatus: '',
    updateOfferDetailSuccess: null,
    updateOfferDetailError: null,
    updateOfferDetailReqStatus: ''
}

const offerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OfferActionTypes.ADD_OFFER_REQUEST_SUCCESS:
            return {
                ...state,
                addOfferSuccess: action.payload,
                addOfferError: null,
                addOfferReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OfferActionTypes.ADD_OFFER_REQUEST_ERROR:
            return {
                ...state,
                addOfferSuccess: null,
                addOfferError: action.payload,
                addOfferReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OfferActionTypes.ADD_OFFER_REQUEST_STATUS:
            return {
                ...state,
                addOfferReqStatus: ''
            }
        case OfferActionTypes.OFFER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                offerListSuccess: action.payload,
                offerListError: null
            }
        case OfferActionTypes.OFFER_LIST_REQUEST_ERROR:
            return {
                ...state,
                offerListSuccess: null,
                offerListError: action.payload
            }
        case OfferActionTypes.OFFER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                offerDetailSuccess: action.payload,
                offerDetailError: null
            }
        case OfferActionTypes.OFFER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                offerDetailSuccess: null,
                offerDetailError: action.payload
            }
        case OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateStatusOfferSuccess: action.payload,
                updateStatusOfferError: null,
                updateStatusOfferReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateStatusOfferSuccess: null,
                updateStatusOfferError: action.payload,
                updateStatusOfferReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateStatusOfferReqStatus: ''
            }
        case OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                updateOfferDetailSuccess: action.payload,
                updateOfferDetailError: null,
                updateOfferDetailReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                updateOfferDetailSuccess: null,
                updateOfferDetailError: action.payload,
                updateOfferDetailReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                updateOfferDetailReqStatus: ''
            }
        case OfferActionTypes.LOGOUT:
            return {
                ...state,
                addOfferSuccess: null,
                addOfferError: null,
                addOfferReqStatus: '',
                offerDetailSuccess: null,
                offerDetailError: null,
                updateStatusOfferSuccess: null,
                updateStatusOfferError: null,
                updateStatusOfferReqStatus: '',
                updateOfferDetailSuccess: null,
                updateOfferDetailError: null,
                updateOfferDetailReqStatus: ''
            }
        default:
            return state
    }
}

export default offerReducer