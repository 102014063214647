import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { displayErrorToast, displaySuccessToast, isEmpty } from "../../../../utils/Utils";
import { businessDetailsRequest, updateBusinessDetailsRequest, updateBusinessDetailsRequestStatus } from '../../../../redux/business/business.action'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import CommonInputFloatFull from "../../../../components/input/common-input-float-full/commonInputFloatFull";
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import { API_CONSTANTS, ROUTES } from "../../../../utils/AppConstants";

const EditBusinessScreen = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { state } = useLocation()
    const { businessDetailSuccess, businessDetailError,
        updateBusinessDetailSuccess, updateBusinessDetailError, updateBusinessDetailReqStatus
    } = useSelector(state => state.business)
    const [name, setName] = useState('')
    let navigate = useNavigate();

    useEffect(() => {
        fetchBusinessDetail()
    }, [])

    const fetchBusinessDetail = () => {
        setLoading(true)
        dispatch(businessDetailsRequest(state))
    }

    useEffect(() => {
        if (!isEmpty(businessDetailSuccess)) {
            setLoading(false)
            if (!isEmpty(businessDetailSuccess.data)) {
                setName(businessDetailSuccess.data.businessName)
            }
        } else if (!isEmpty(businessDetailError)) {
            setLoading(false)
        }
    }, [businessDetailSuccess, businessDetailError])

    useEffect(() => {
        if (updateBusinessDetailReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateBusinessDetailSuccess.message)
            fetchBusinessDetail()
        } else if (updateBusinessDetailReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateBusinessDetailError.message)
        }
        if (updateBusinessDetailReqStatus?.length) {
            dispatch(updateBusinessDetailsRequestStatus(''))
        }
    }, [updateBusinessDetailSuccess, updateBusinessDetailError, updateBusinessDetailReqStatus])

    const handleUpdateClick = () => {
        if (isEmpty(name)) {
            displayErrorToast('Business name cannot be blank')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('businessId', state)
            fd.append('businessName', name)
            dispatch(updateBusinessDetailsRequest(fd))
        }
    }

    const handleEditClick = item => {
        navigate(ROUTES.editLocation, { state: item.locationId })
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Edit Details' />
                <CommonFormBorder>
                    <SubTextLabel label='Edit details to update business details' />
                    <CommonInputFloatFull
                        type="name"
                        id="name"
                        placeholder="Business Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        isRequired={true}
                    />
                    <FormFieldLabel className='up-bold-label' label='Business Locations' />
                    {!isEmpty(businessDetailSuccess) && !isEmpty(businessDetailSuccess.data) && !isEmpty(businessDetailSuccess.data.locationList) && <div>
                        {businessDetailSuccess.data.locationList.map(e => (<div className="eb-loc-row-container">
                            <div className="eb-loc-row-content-container">
                                <div>{e.name}</div>
                                <div>{e.address}</div>
                                {!isEmpty(e.currencyLabel) && !isEmpty(e.currencySymbol) && <div>{e.currencyLabel} - {e.currencySymbol}</div>}
                            </div>
                            <div className="eb-loc-row-btn-container">
                                <div className="eb-loc-row-btn-label" onClick={() => { handleEditClick(e) }}>Edit</div>
                            </div>
                        </div>))}
                    </div>}
                    {!isEmpty(businessDetailSuccess) && !isEmpty(businessDetailSuccess.data) && isEmpty(businessDetailSuccess.data.locationList) && <div className="content-error">No Business Location found. Try adding business location(s)</div>}
                    <ButtonRow>
                        <CommonButton label='Update'
                            onClick={() => { handleUpdateClick() }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EditBusinessScreen