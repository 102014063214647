import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import { offerDetailRequest, updateOfferDetailRequest, updateOfferDetailRequestStatus } from '../../../redux/offer/offer.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import { CATEGORIES } from "../../../utils/Categories";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Select from 'react-select'
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonCheckbox from "../../../components/common-checkbox/commonCheckbox";

const OfferDetails = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { offerDetailSuccess, offerDetailError,
        updateOfferDetailSuccess, updateOfferDetailError, updateOfferDetailReqStatus } = useSelector((state) => state.offer)
    const { user } = useSelector(state => state.user)
    const { state } = useLocation()
    const [offerName, setOfferName] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [minAmount, setMinAmount] = useState('')
    const [maxAmount, setMaxAmount] = useState('')
    const [brandName, setBrandName] = useState('')
    const [categoryDropdown, setCategoryDropdown] = useState()
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        fetchOfferDetails()
    }, [])

    const fetchOfferDetails = () => {
        setLoading(true)
        dispatch(offerDetailRequest(state))
    }

    useEffect(() => {
        if (!isEmpty(offerDetailSuccess)) {
            setLoading(false)
            if (!isEmpty(offerDetailSuccess.data)) {
                setOfferName(offerDetailSuccess.data.offerName)
                setMinAmount(offerDetailSuccess.data.minimumReceiptAmount)
                setMaxAmount(offerDetailSuccess.data.maximumReceiptAmount)
                setBrandName(offerDetailSuccess.data.brand)
                setStartDate(new Date(offerDetailSuccess.data.startDate))
                setEndDate(new Date(offerDetailSuccess.data.endDate))
                const category = CATEGORIES.find(e => e.categoryKey === offerDetailSuccess.data.categoryKey)
                if (!isEmpty(category)) {
                    setCategoryDropdown(category)
                }
                setIsActive(offerDetailSuccess.data.isActive)
            }
        } else if (!isEmpty(offerDetailError)) {
            setLoading(false)
        }
    }, [offerDetailSuccess, offerDetailError])

    useEffect(() => {
        if (updateOfferDetailReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateOfferDetailSuccess.message)
            fetchOfferDetails()
        } else if (updateOfferDetailReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateOfferDetailError.message)
        }
        if (updateOfferDetailReqStatus?.length) {
            displayErrorToast(updateOfferDetailRequestStatus(''))
        }
    }, [updateOfferDetailSuccess, updateOfferDetailError, updateOfferDetailReqStatus])

    const handleUpdateClick = () => {
        if (isEmpty(offerName)) {
            displayErrorToast('Offer name cannot be blank')
        } else if (isEmpty(brandName)) {
            displayErrorToast('Offer brand name cannot be blank')
        } else if (isNull(categoryDropdown)) {
            displayErrorToast('Offer category not selected')
        } else if (isEmpty(startDate)) {
            displayErrorToast('Start date not selected')
        } else if (isEmpty(endDate)) {
            displayErrorToast('End date cannot not selected')
        } else if (isEmpty(minAmount)) {
            displayErrorToast('Minimum bill amount cannot be blank')
        } else if (Number(minAmount) < 0) {
            displayErrorToast('Minimum bill amount cannot be less than zero')
        } else if (isEmpty(maxAmount)) {
            displayErrorToast('Maximum bill amount cannot be blank')
        } else if (Number(maxAmount) < Number(minAmount)) {
            displayErrorToast('Maximum bill amount cannot be less than minimum bill amount')
        } else {
            setLoading(true)
            const data = {
                offerId: state,
                offerName: offerName,
                brand: brandName,
                categoryId: categoryDropdown.categoryId,
                categoryKey: categoryDropdown.categoryKey,
                startDate: moment(startDate).utc().format("YYYY-MM-DD hh:mm:ss A"),
                endDate: moment(endDate).utc().format("YYYY-MM-DD hh:mm:ss A"),
                minimumReceiptAmount: minAmount,
                maximumReceiptAmount: maxAmount,
                isActive: isActive
            }
            dispatch(updateOfferDetailRequest(data))
        }
    }

    const isOfferExpired = (offerEndDate, currentDate) => {
        if (moment(currentDate).isAfter(moment(offerEndDate))) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Edit Offer' />
                <CommonFormBorder>
                    {!isEmpty(offerDetailSuccess) && !isEmpty(offerDetailSuccess.data) && <div className="od-status-label">
                        {offerDetailSuccess.data.isActive ? <div className="offer-ongoing-label">On Going</div> : isOfferExpired(offerDetailSuccess.data.endDate, offerDetailSuccess.data.currentTime) ? <div className="offer-expired-label">Expired</div> : <div className="offer-expired-label">In Active</div>}
                    </div>}
                    <SubTextLabel label='Edit details to update offer' />
                    <CommonInputFloatFull
                        type='text'
                        id='offerName'
                        onChange={e => setOfferName(e.target.value)}
                        placeholder='Offer Name'
                        value={offerName}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='brand'
                        onChange={e => setBrandName(e.target.value)}
                        placeholder='Brand Name'
                        value={brandName}
                    />
                    <FormFieldLabel label='Select Category' />
                    {CATEGORIES && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={categoryDropdown}
                        getOptionLabel={e => `${e.name}`}
                        getOptionValue={e => e}
                        options={CATEGORIES}
                        placeholder='Select Category'
                        onChange={e => {
                            setCategoryDropdown(e)
                        }}
                    />}
                    <CommonInputRow>
                        <div className="as-date-container">
                            <FormFieldLabel label='Start Date' />
                            <DatePicker
                                className="as-date-picker"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                        <div className="as-date-container">
                            <FormFieldLabel label='End Date' />
                            <DatePicker
                                minDate={startDate}
                                className="as-date-picker"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                    </CommonInputRow>
                    <FormFieldLabel className='up-bold-label' label='Receipt Pricing' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='number'
                            id='minAmount'
                            onChange={e => setMinAmount(e.target.value)}
                            placeholder='Min. Amount'
                            value={minAmount}
                        />
                        <CommonInputFloatHalf
                            type='number'
                            id='maxAmount'
                            onChange={e => setMaxAmount(e.target.value)}
                            placeholder='Max. Amount'
                            value={maxAmount}
                        />
                    </CommonInputRow>
                    <CommonCheckbox id='status'
                        label='Is Offer Active'
                        onChange={e => setIsActive(e.target.checked)}
                        value={isActive}
                    />
                    <ButtonRow>
                        <CommonButton label='Update' onClick={() => handleUpdateClick()} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OfferDetails