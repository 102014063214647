import { all, call } from 'redux-saga/effects'
import {
    changePasswordReq, cityListReq, countryListReq, forgotPasswordReq,
    loginUserReq, operatorListReq, operatorRegisterReq, registerUserReq, stateListReq,
    subscriptionListReq, customerLoginReq, operatorDetailReq,
    updateOperatorLocationReq, updateUserNotificationTokenReq
} from './user/user.saga'
import { addCashbackRequestReq, cashbackRequestListReq, cashbackRequestUpdateStatusReq } from './cashback/cashback.saga'
import {
    customerAddBankDetailReq, customerDetailReq, customerListReq, customerUpdateInfoReq,
    customerUpdateBankInfoReq, searchOrAddCustomerReq
} from './customer/customer.saga'

import { addBusinessReq, businessDetailReq, updateBusinessDetailReq, userBusinessListReq } from './business/business.saga'
import { addBusinessLocationReq, businessLocationDetailReq, businessLocationListReq, updateBusinessLocationReq } from './business-location/businessLocation.saga'
import { addOfferReq, offerDetailReq, offerListReq, offerUpdateStatusReq, offerUpdateDetailReq } from './offer/offer.saga'
import { addCustomerCashbackReq, customerCashbackListReq } from './customer-cashback/customerCashback.saga'
import {
    addInvoiceReq, invoiceDetailReq, invoiceListReq,
    invoiceUpdatePaymentReq
} from './invoice/invoice.saga'

export default function* rootSaga() {
    yield all([
        call(loginUserReq),
        call(customerLoginReq),
        call(registerUserReq),
        call(subscriptionListReq),
        call(changePasswordReq),
        call(forgotPasswordReq),
        call(cityListReq),
        call(countryListReq),
        call(stateListReq),
        call(operatorListReq),
        call(operatorRegisterReq),
        call(addCashbackRequestReq),
        call(cashbackRequestListReq),
        call(cashbackRequestUpdateStatusReq),
        call(customerAddBankDetailReq),
        call(customerDetailReq),
        call(customerListReq),
        call(customerUpdateInfoReq),
        call(operatorDetailReq),
        call(updateOperatorLocationReq),
        call(updateUserNotificationTokenReq),
        call(customerUpdateBankInfoReq),
        call(searchOrAddCustomerReq),
        call(addBusinessReq),
        call(businessDetailReq),
        call(updateBusinessDetailReq),
        call(userBusinessListReq),
        call(addBusinessLocationReq),
        call(businessLocationDetailReq),
        call(businessLocationListReq),
        call(updateBusinessLocationReq),
        call(addOfferReq),
        call(offerDetailReq),
        call(offerListReq),
        call(offerUpdateStatusReq),
        call(offerUpdateDetailReq),
        call(addCustomerCashbackReq),
        call(customerCashbackListReq),
        call(addInvoiceReq),
        call(invoiceDetailReq),
        call(invoiceListReq),
        call(invoiceUpdatePaymentReq),
    ])
}