import { OfferActionTypes } from './offer.types'

//Add Offer
export const addOfferRequest = data => ({
    type: OfferActionTypes.ADD_OFFER_REQUEST,
    payload: data
})

export const addOfferRequestSuccess = data => ({
    type: OfferActionTypes.ADD_OFFER_REQUEST_SUCCESS,
    payload: data
})

export const addOfferRequestError = data => ({
    type: OfferActionTypes.ADD_OFFER_REQUEST_ERROR,
    payload: data
})

export const addOfferRequestStatus = data => ({
    type: OfferActionTypes.ADD_OFFER_REQUEST_STATUS,
    payload: data
})

//Offer List
export const offerListRequest = data => ({
    type: OfferActionTypes.OFFER_LIST_REQUEST,
    payload: data
})

export const offerListRequestSuccess = data => ({
    type: OfferActionTypes.OFFER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const offerListRequestError = data => ({
    type: OfferActionTypes.OFFER_LIST_REQUEST_ERROR,
    payload: data
})

//Offer Detail
export const offerDetailRequest = data => ({
    type: OfferActionTypes.OFFER_DETAIL_REQUEST,
    payload: data
})

export const offerDetailRequestSuccess = data => ({
    type: OfferActionTypes.OFFER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const offerDetailRequestError = data => ({
    type: OfferActionTypes.OFFER_DETAIL_REQUEST_ERROR,
    payload: data
})

//Offer Update Status
export const offerUpdateStatusRequest = data => ({
    type: OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST,
    payload: data
})

export const offerUpdateStatusRequestSuccess = data => ({
    type: OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const offerUpdateStatusRequestError = data => ({
    type: OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const offerUpdateStatusRequestStatus = data => ({
    type: OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})

//Update Offer Detail
export const updateOfferDetailRequest = data => ({
    type: OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST,
    payload: data
})

export const updateOfferDetailRequestSuccess = data => ({
    type: OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const updateOfferDetailRequestError = data => ({
    type: OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_ERROR,
    payload: data
})

export const updateOfferDetailRequestStatus = data => ({
    type: OfferActionTypes.UPDATE_OFFER_DETAILS_REQUEST_STATUS,
    payload: data
})