export const OfferActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_OFFER_REQUEST: 'ADD_OFFER_REQUEST',
    ADD_OFFER_REQUEST_SUCCESS: 'ADD_OFFER_REQUEST_SUCCESS',
    ADD_OFFER_REQUEST_ERROR: 'ADD_OFFER_REQUEST_ERROR',
    ADD_OFFER_REQUEST_STATUS: 'ADD_OFFER_REQUEST_STATUS',

    OFFER_DETAIL_REQUEST: 'OFFER_DETAIL_REQUEST',
    OFFER_DETAIL_REQUEST_SUCCESS: 'OFFER_DETAIL_REQUEST_SUCCESS',
    OFFER_DETAIL_REQUEST_ERROR: 'OFFER_DETAIL_REQUEST_ERROR',

    OFFER_LIST_REQUEST: 'OFFER_LIST_REQUEST',
    OFFER_LIST_REQUEST_SUCCESS: 'OFFER_LIST_REQUEST_SUCCESS',
    OFFER_LIST_REQUEST_ERROR: 'OFFER_LIST_REQUEST_ERROR',

    OFFER_UPDATE_STATUS_REQUEST: 'OFFER_UPDATE_STATUS_REQUEST',
    OFFER_UPDATE_STATUS_REQUEST_SUCCESS: 'OFFER_UPDATE_STATUS_REQUEST_SUCCESS',
    OFFER_UPDATE_STATUS_REQUEST_ERROR: 'OFFER_UPDATE_STATUS_REQUEST_ERROR',
    OFFER_UPDATE_STATUS_REQUEST_STATUS: 'OFFER_UPDATE_STATUS_REQUEST_STATUS',

    UPDATE_OFFER_DETAILS_REQUEST: 'UPDATE_OFFER_DETAILS_REQUEST',
    UPDATE_OFFER_DETAILS_REQUEST_SUCCESS: 'UPDATE_OFFER_DETAILS_REQUEST_SUCCESS',
    UPDATE_OFFER_DETAILS_REQUEST_ERROR: 'UPDATE_OFFER_DETAILS_REQUEST_ERROR',
    UPDATE_OFFER_DETAILS_REQUEST_STATUS: 'UPDATE_OFFER_DETAILS_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
