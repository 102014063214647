import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { businessLocationListRequest } from '../../../../redux/business-location/businessLocation.action'
import { userBusinessListRequest } from '../../../../redux/business/business.action'
import Select from 'react-select'
import moment from "moment";
import TopButton from '../../../../components/button/top-button/topButton'
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";

const LocationListScreen = props => {
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    let navigate = useNavigate();
    const LOCATION_LIST = 'LOCATION_LIST'
    const EDIT_LOCATION = 'EDIT_LOCATION'
    const ADD_LOCATION = 'ADD_LOCATION'

    useEffect(() => {
        const data = {
            screen: 'LOCATION_LIST',
            screenClass: 'LOCATION_LIST'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.setItem(APP_CONSTANTS.LOCATION_LIST, true)
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data))
    }, [])

    useEffect(() => {
        if (!isNull(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.LOCATION_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.LOCATION_LIST, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isNull(userBusinessListError)) {
            setLoading(false)
        }

    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isNull(businessLocationListSuccess)) {
            setLoading(false)
        } else if (!isNull(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            fetchLocationListReq(selectedBusiness.businessId)
        }
    }, [selectedBusiness])

    const fetchLocationListReq = (restId) => {
        setLoading(true)
        const data = {
            businessId: restId
        }
        dispatch(businessLocationListRequest(data))
    }

    const addLocationClickHandle = () => {
        navigate(ROUTES.addLocation)
    }

    const handleCardClick = element => {
        // navigate(ROUTES.editLocation, { state: element });
    }

    const handleCardDetailClick = element => {
        // navigate(ROUTES.locationDetail, { state: element.locationId });
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='mt-2'>
                    <ScreenLabel label='Locations' />
                    <TopButton label='Add Location'
                        onClick={() => addLocationClickHandle()} />
                </CommonInputRow>
                {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                    className='select-full-width-no-border'
                    value={selectedBusiness}
                    getOptionLabel={e => e.businessName}
                    getOptionValue={e => e}
                    options={userBusinessListSuccess.data}
                    placeholder='Select Business'
                    onChange={e => setSelectedBusiness(e)} />}
                {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                <div className="table-scroll-container">
                    {!isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <table class="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="loc-table-col-title">Name</th>
                                <th scope="col" className="loc-table-col-title">Address</th>
                                <th scope="col" className="loc-table-col-title">Location</th>
                                <th scope="col" className="loc-table-col-title">Status</th>
                                <th scope="col" className="loc-table-col-title">Date</th>
                                {/* <th scope="col" className="loc-table-col-title text-center">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {businessLocationListSuccess.data.map(element => (
                                <tr className="loc-table-row-container">
                                    <td className="loc-table-col-item">{element.name}</td>
                                    <td className="loc-table-col-item">{element.address ? element.address : '-'}</td>
                                    <td className="loc-table-col-item">{element.city ? element.city : '-'}, {element.state ? element.state : '-'}, {element.country ? element.country : '-'}</td>
                                    <td className={`loc-table-col-item ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="loc-table-col-item">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    {/* <td className="loc-table-col-item">
                                        <div className="loc-list-button-container">
                                            <div className="loc-list-button" onClick={() => { handleCardDetailClick(element) }}>Detail</div>
                                            <div className="loc-list-button" onClick={() => { handleCardClick(element) }}>Edit</div>
                                        </div>
                                    </td> */}
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default LocationListScreen