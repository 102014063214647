import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { addBusinessRequest, addBusinessRequestStatus } from '../../../../redux/business/business.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, screenCaptureAnalytics } from '../../../../utils/Utils'
import Select from 'react-select'
import CommonButton from "../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import CommonInputFloatFull from "../../../../components/input/common-input-float-full/commonInputFloatFull";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../components/input/common-input-float-half/commonInputFloafHalf";
import { CurrencyLabel } from "../../../../utils/CurrencyLabel";

const AddBusinessScreen = props => {
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [name, setName] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const { addBusinessSuccess, addBusinessError, addBusinessReqStatus } = useSelector(state => state.business)
    const [address, setAddress] = useState('')
    const [taxType, setTaxType] = useState('')
    const [taxNumber, setTaxNumber] = useState('')
    const [currencyDropdown, setCurrencyDropdown] = useState()

    useEffect(() => {
        const data = {
            screen: 'ADD_BUSINESS',
            screenClass: 'ADD_BUSINESS'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
    }, [])

    useEffect(() => {
        if (!isEmpty(countryListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isEmpty(stateListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isEmpty(cityListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (addBusinessReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast('Business added successfully. Add additional Location to the business from location menu.')
            clearFields()
        } else if (addBusinessReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addBusinessError.message)
        }

        if (addBusinessReqStatus?.length) {
            dispatch(addBusinessRequestStatus(''))
        }
    }, [addBusinessSuccess, addBusinessError, addBusinessReqStatus])

    const clearFields = () => {
        setName('')
        setImagePath(null)
        setImageName('')
        setTaxType('')
        setTaxNumber('')
        setAddress('')
    }

    const handleNameChange = e => {
        const businessName = e.target.value
        setName(businessName)
    }

    const handleImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
        }
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Business name cannot be blank')
        } else if (isNull(currencyDropdown)) {
            displayErrorToast('Currency not selected')
        } else if (isEmpty(address)) {
            displayErrorToast('Address cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Country is not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('State is not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('City is not selected')
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('businessName', name)
            fd.append('userId', user.data.userDetails.userId)
            fd.append('email', user.data.userDetails.email)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            fd.append('address', address)
            fd.append('country', countryDropdown.name)
            fd.append('countryCode', countryDropdown.iso2)
            fd.append('state', stateDropdown.name)
            fd.append('stateCode', stateDropdown.iso2)
            fd.append('city', cityDropdown.name)
            fd.append('currencyLabel', currencyDropdown.code)
            fd.append('currencySymbol', currencyDropdown.symbol)
            fd.append('currencyRegion', currencyDropdown.region)
            dispatch(addBusinessRequest(fd))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2">
                    <ScreenLabel label='Add Business' />
                </div>

                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new business' />
                    <CommonInputFloatFull
                        type="name"
                        id="name"
                        placeholder="Business Name"
                        value={name}
                        onChange={handleNameChange}
                        isRequired={true} />
                    <FormFieldLabel label='Select Currency' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={currencyDropdown}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setCurrencyDropdown(e)
                        }}
                    />}
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    <FormFieldLabel label='Address Information' />
                    <CommonInputFloatFull
                        type="text"
                        id="address"
                        placeholder="Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)} />
                    {countryListSuccess && <Select
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                            setCountryCode(e.iso2)
                            fetchStateList(e.iso2)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border margin-top-10'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="top"
                        className='select-full-width-no-border margin-top-10'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <FormFieldLabel label='Business Image/Logo' />
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <ButtonRow>
                        <CommonButton
                            label='Submit'
                            onClick={() => submitHandler()} />

                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddBusinessScreen