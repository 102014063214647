import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { offerListRequest } from '../../../redux/offer/offer.action'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { displayCurrencySymbolLeft, displayErrorToast, isEmpty, isLoggedInUserVendor, isNull } from "../../../utils/Utils";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { config } from "../../../config";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import CommonButton from "../../../components/button/common-button/commonButton";
import NavigationBar from "../../navigation-bar/navigationBar";
import TopButton from "../../../components/button/top-button/topButton";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { CATEGORIES } from "../../../utils/Categories";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import NavBarComponent from "../../landing/nav-bar/navBar";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import { userBusinessListRequest } from '../../../redux/business/business.action'
import { businessLocationListRequest } from '../../../redux/business-location/businessLocation.action'

const OfferList = props => {
    const [isLoading, setLoading] = useState(false)
    const { offerListSuccess, offerListError, commonError } = useSelector((state) => state.offer)
    const { isLogin, user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [showSelectLocationModal, setShowSelectLocatonModal] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [allOfferExpired, setAllOfferExpired] = useState(false)
    const [categoryDropdown, setCategoryDropdown] = useState()
    const [searchQuery, setSearchQuery] = useState('')
    const [emptyFieldCounter, setEmptyFieldCounter] = useState(0)
    const [zipcode, setZipCode] = useState('')
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        if (isLoggedInUserVendor(user)) {
            fetchBusinessList()
        } else {
            fetchOfferList()
        }
        if (!isLoggedInUserVendor(user))
            dispatch(countryListRequest())
    }, [])

    const fetchBusinessList = () => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data))
    }

    const fetchOfferList = () => {
        setLoading(true)
        const data = {}
        if (!isEmpty(selectedCategory)) {
            data.category = selectedCategory.categoryKey
        }
        if (!isEmpty(zipcode)) {
            data.zipcode = zipcode
        }
        if (!isNull(countryDropdown)) {
            data.country = countryDropdown.name
            if (!isNull(stateDropdown)) {
                data.state = stateDropdown.name
            }
            if (!isNull(cityDropdown)) {
                data.city = cityDropdown.name
            }
        }
        if (!isEmpty(searchQuery)) {
            data.searchQuery = searchQuery
        }

        // // if (!isEmpty(categoryDropdown)) {
        // //     let category = categoryDropdown.map(item => item.categoryKey).join('+')
        // //     updateData.category = category
        // // }
        // if (!isEmpty(searchQuery)) {
        //     updateData.searchQuery = searchQuery
        // }
        if (!isNull(user) && isLoggedInUserVendor(user) && !isNull(selectedBusiness) && !isNull(selectedLocation)) {
            data.businessId = parseFloat(selectedBusiness.businessId)
            data.locationId = parseFloat(selectedLocation.locationId)
            // updateData.businessId = Number(user.data.userDetails.businessDetails.businessId)
        }
        dispatch(offerListRequest(data))
    }

    // const fetchOfferList = (data) => {
    //     setLoading(true)
    //     const updateData = {
    //         ...data
    //     }
    //     // if (!isEmpty(categoryDropdown)) {
    //     //     let category = categoryDropdown.map(item => item.categoryKey).join('+')
    //     //     updateData.category = category
    //     // }
    //     if (!isEmpty(searchQuery)) {
    //         updateData.searchQuery = searchQuery
    //     }
    //     if (!isNull(user) && isLoggedInUserVendor(user) && !isNull(user.data.userDetails.businessDetails)) {
    //         updateData.businessId = Number(user.data.userDetails.businessDetails.businessId)
    //     }
    //     dispatch(offerListRequest(updateData))
    // }

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(offerListSuccess)) {
            setLoading(false)
            if (!isEmpty(offerListSuccess.data)) {
                let expiredOffers = offerListSuccess.data.filter(e => Number(e.status) === 0)
                if (expiredOffers.length === offerListSuccess.data.length) {
                    setAllOfferExpired(true)
                } else {
                    setAllOfferExpired(false)
                }
            }
        } else if (!isNull(offerListError)) {
            setLoading(false)
        }
    }, [offerListSuccess, offerListError])

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
            fetchOfferList()
        }
    }, [selectedLocation])

    const handleSelectLocation = () => {
        const data = {}
        // if (!isEmpty(zipcode)) {
        //     data.zipcode = zipcode
        //     localStorage.setItem('zipcode', zipcode)
        // }
        // if (!isNull(countryDropdown)) {
        //     data.country = countryDropdown.name
        //     localStorage.setItem('ofCountry', JSON.stringify(countryDropdown))
        //     if (!isNull(stateDropdown)) {
        //         data.state = stateDropdown.name
        //         localStorage.setItem('ofState', JSON.stringify(stateDropdown))
        //     }
        //     if (!isNull(cityDropdown)) {
        //         data.city = cityDropdown.name
        //         localStorage.setItem('ofCit', JSON.stringify(cityDropdown))
        //     }
        // }
        // fetchOfferList(data)
        fetchOfferList()
        setShowSelectLocatonModal(false)
    }

    const handleAddOfferClick = () => {
        navigate(ROUTES.addOffer)
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSearchClick = () => {
        if (isEmpty(searchQuery)) {
            if (Number(emptyFieldCounter) < 3) {
                let currentCounter = emptyFieldCounter
                setEmptyFieldCounter(currentCounter + 1)
                // const data = {}
                // fetchOfferList(data)
                fetchOfferList()
            } else {
                displayErrorToast('Please try refreshing the page.')
            }
        } else {
            // const data = {}
            // fetchOfferList(data)
            fetchOfferList()
        }

    }

    const getCategoryName = (catKey) => {
        const category = CATEGORIES.find(e => e.categoryKey === catKey)
        if (!isNull(category)) {
            return category.name
        } else return ''
    }

    const isOfferExpired = (offerEndDate, currentDate) => {
        // const momen1 = new Date(offerEndDate)
        // const moment2 = new Date(currentDate)
        // if (momen1.getTime() > moment2.getTime()) {
        //     return true
        // } else {
        //     return false
        // }
        if (moment(currentDate).isAfter(moment(offerEndDate))) {
            return true
        } else {
            return false
        }
    }

    const getCatIcon = (categoryKey) => {
        if (categoryKey === 'CARANDAUTOMOTIVE') {
            return require('../../../assets/category/ic_cat_automobile.png')
        } else if (categoryKey === 'BABYANDKIDS') {
            return require('../../../assets/category/ic_cat_kids.png')
        } else if (categoryKey === 'BANKING') {
            return require('../../../assets/category/ic_cat_banking.png')
        } else if (categoryKey === 'BUSINESS') {
            return require('../../../assets/category/ic_cat_business.png')
        } else if (categoryKey === 'CLOTHING') {
            return require('../../../assets/category/ic_cat_clothing.png')
        } else if (categoryKey === 'FASHIONACCESSORIES') {
            return require('../../../assets/category/ic_cat_fashion_accessories.png')
        } else if (categoryKey === 'DEPARTMENTSTORES') {
            return require('../../../assets/category/ic_cat_store.png')
        } else if (categoryKey === 'ELECTRONICS') {
            return require('../../../assets/category/ic_cat_electronics.png')
        } else if (categoryKey === 'FINANCIAL') {
            return require('../../../assets/category/ic_cat_finance.png')
        } else if (categoryKey === 'FOODANDDRINK') {
            return require('../../../assets/category/ic_cat_food.png')
        } else if (categoryKey === 'GIFTS') {
            return require('../../../assets/category/ic_cat_gift.png')
        } else if (categoryKey === 'HEALTHANDBEAUTY') {
            return require('../../../assets/category/ic_cat_health.png')
        } else if (categoryKey === 'HOMEGARDEN') {
            return require('../../../assets/category/ic_cat_home.png')
        } else if (categoryKey === 'JEWELRYANDWATCHES') {
            return require('../../../assets/category/ic_cat_jewelry.png')
        } else if (categoryKey === 'ENTERTAINMENT') {
            return require('../../../assets/category/ic_cat_entertainment.png')
        } else if (categoryKey === 'SPORTSANDFITNESS') {
            return require('../../../assets/category/ic_cat_sports.png')
        } else if (categoryKey === 'TRAVEL') {
            return require('../../../assets/category/ic_cat_travel.png')
        } else if (categoryKey === 'UTILITIESANDSERVICES') {
            return require('../../../assets/category/ic_cat_utilities.png')
        } else if (categoryKey === 'FUEL') {
            return require('../../../assets/category/ic_cat_fuel.png')
        }
    }

    const handleCategoryClick = e => {
        setSelectedCategory(e)
    }

    useEffect(() => {
        if (!isEmpty(selectedCategory)) {
            fetchOfferList()
        }
    }, [selectedCategory])

    const handleViewDetailClick = item => {
        navigate(ROUTES.offerDetail, { state: item.offerId })
    }

    return (
        <>
            <div>
                {!isLogin && isNull(user) && <NavBarComponent navigate={navigate}
                    showHome={true}
                    showCustomerLogin={true}
                    isWhiteBackground={true}
                    showForBusiness={true} />}
                {isLogin && !isNull(user) && <ScreenLabel label='Offers' />}

                <CommonScreenContent>
                    {!isLoggedInUserVendor(user) &&
                        <div className="ol-search-parent-container">
                            <div className="ol-search-row-parent-container">
                                <div className="ol-search-input-container">
                                    <input type="text" className="ol-input-no-border" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder="Search Offer" />
                                </div>
                                <div className="ol-search-icon-container" onClick={() => { handleSearchClick() }}>
                                    <img src={require('../../../assets/ic_search_orange.png')} className="img" />
                                </div>
                            </div>
                            <div className="ol-search-filter-container" onClick={() => { setShowSelectLocatonModal(true) }}>
                                <img src={require('../../../assets/ic_filter_location.png')} className="img" />
                            </div>
                        </div>}
                    {isLoggedInUserVendor(user) &&
                        <div className="offer-add-btn-container">
                            <TopButton label='Add Offer' onClick={() => { handleAddOfferClick() }} />
                        </div>}
                    {isLoggedInUserVendor(user) === true &&
                        <CommonInputRow className='margin-top-10'>
                            <div className="vcl-list-select-container">
                                <div className="vcl-list-select-heading">Select Business</div>
                                {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                                    className='select-full-width-no-border'
                                    value={selectedBusiness}
                                    getOptionLabel={e => e.businessName}
                                    getOptionValue={e => e}
                                    options={userBusinessListSuccess.data}
                                    placeholder='Select Business'
                                    onChange={e => setSelectedBusiness(e)} />}
                                {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                            </div>
                            {!isEmpty(selectedBusiness) && <div className="vcl-list-select-container">
                                <div className="vcl-list-select-heading">Select Location</div>
                                {!isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <Select
                                    className='select-full-width-no-border'
                                    value={selectedLocation}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e}
                                    options={businessLocationListSuccess.data}
                                    placeholder='Select Location'
                                    onChange={e => setSelectedLocation(e)} />}
                                {!isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                            </div>}
                        </CommonInputRow>}
                    {/* {!isNull(offerListSuccess) && !isEmpty(offerListSuccess.data) && !allOfferExpired && <div className="offer-list-container"> */}
                    {!isNull(CATEGORIES) && <div className="ol-category-parent-container">
                        <div className="ol-category-scroll-container">
                            {CATEGORIES.map(e => (
                                <div className={`ol-category-item ${selectedCategory?.categoryId === e.categoryId ? 'ol-category-item-selected' : ''}`}
                                    onClick={() => { handleCategoryClick(e) }}>{e.name}</div>
                            ))}
                        </div>
                    </div>}
                    {!isNull(offerListSuccess) && !isEmpty(offerListSuccess.data) && <div className="offer-list-container">
                        {offerListSuccess.data.map(element => {
                            return (
                                <div className='offer-list-row-item-container'>
                                    {element.isActive ? <div className="offer-ongoing-label">On Going</div> : isOfferExpired(element.endDate, element.currentTime) ? <div className="offer-expired-label">Expired</div> : <div className="offer-expired-label">In Active</div>}
                                    {/* {isOfferExpired(element.endDate, element.currentTime) && <div className="offer-expired-label">Expired</div>} */}
                                    <div className="ol-row-item-parent-container">
                                        <div className="ol-row-item-img-container">
                                            <img src={`${config.imageUrl}${element.image}`} className="img" />
                                        </div>
                                        <div className="ol-row-item-content-container">
                                            <div className="offer-business-label">{element.businessDetail?.businessName}</div>
                                            <div className="offer-title">{element.offerName}</div>
                                            <div className="offer-validity-label">Valid from {moment(element.startDate).utc().format('Do MMM YY')} till {moment(element.endDate).utc().format('Do MMM YY')}</div>
                                            <div className="offer-bill-label">&#10033; Min. bill of {displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} {element.minimumReceiptAmount} {!displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} upto {displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} {element.maximumReceiptAmount} {!displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''}</div>
                                            <div className="ol-offer-cat-row-container">
                                                <div className="ol-offer-cat-row-icon-container">
                                                    <img src={getCatIcon(element.categoryKey)} className="img" />
                                                </div>
                                                <div className="ol-offer-category-container">{getCategoryName(element.categoryKey)}</div>
                                            </div>
                                            <div className="offer-brand-label">{element.brand ? element.brand : 'Brand'}</div>
                                            {isLoggedInUserVendor(user) && <div className="offer-view-details" onClick={() => { handleViewDetailClick(element) }}>View Details</div>}
                                        </div>
                                    </div>

                                </div>
                            )
                            // if (isLoggedInUserVendor(user)) {
                            //     return (
                            //         <Card sx={{ maxWidth: 345, marginRight: 1, marginTop: 1 }}>
                            //             <CardActionArea>
                            //                 <CardMedia
                            //                     component="img"
                            //                     height="160"
                            //                     image={`${config.imageUrl}${element.image}`}
                            //                     alt="green iguana"
                            //                 />
                            //                 <CardContent>
                            //                     <div className="offer-content-container">
                            //                         <div className="offer-brand-label">{element.brand ? element.brand : 'Brand'}</div>
                            //                         <div className="offer-title">{element.offerName}</div>
                            //                         {isOfferExpired(element.endDate, element.currentTime) && <div className="offer-expired-label">Expired</div>}
                            //                         <div className="offer-validity-label">Valid from: {moment(element.startDate).utc().format('DD/MM/YY')} to {moment(element.endDate).utc().format('DD/MM/YY')}</div>
                            //                         <div className="offer-bill-label">&#10033; Min. bill of {getAmountWithCurrency(element, element.minimumReceiptAmount)} upto {getAmountWithCurrency(element, element.maximumReceiptAmount)}</div>
                            //                         <div className="offer-category-container">{getCategoryName(element.categoryKey)}</div>
                            //                         <div className="offer-business-label">{element.businessDetail?.businessName}</div>
                            //                     </div>
                            //                 </CardContent>
                            //             </CardActionArea>
                            //         </Card>
                            //     )
                            // } else {
                            //     if (Number(element.status) === 1) {
                            //         return (
                            //             <Card sx={{ maxWidth: 345, marginRight: 1, marginTop: 1 }}>
                            //                 <CardActionArea>
                            //                     <CardMedia
                            //                         component="img"
                            //                         height="160"
                            //                         image={`${config.imageUrl}${element.image}`}
                            //                         alt="green iguana"
                            //                     />
                            //                     <CardContent>
                            //                         <div className="offer-content-container">
                            //                             <div className="offer-brand-label">{element.brand ? element.brand : 'Brand'}</div>
                            //                             <div className="offer-title">{element.offerName}</div>
                            //                             {isOfferExpired(element.endDate, element.currentTime) && <div className="offer-expired-label">Expired</div>}
                            //                             <div className="offer-validity-label">Valid from: {moment(element.startDate).utc().format('DD/MM/YY')} to {moment(element.endDate).utc().format('DD/MM/YY')}</div>
                            //                             <div className="offer-bill-label">&#10033; Min. bill of {displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} {element.minimumReceiptAmount} {!displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} upto {displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''} {element.maximumReceiptAmount} {!displayCurrencySymbolLeft(element.currencyRegion) ? element.currencySymbol : ''}</div>
                            //                             <div className="offer-category-container">{getCategoryName(element.categoryKey)}</div>
                            //                             <div className="offer-business-label">{element.businessDetail?.businessName}</div>
                            //                             {/* <div className="offer-view-details">View Details</div> */}
                            //                         </div>
                            //                     </CardContent>
                            //                 </CardActionArea>
                            //             </Card>
                            //         )
                            //     }
                            // }
                        })}
                    </div>}
                    {!isNull(offerListError) && <div className="content-error">{offerListError.message}</div>}
                    {/* {allOfferExpired && <div className="content-error">No Offer Found</div>} */}
                </CommonScreenContent>
            </div>

            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
            <Modal
                onHide={() => { setShowSelectLocatonModal(false) }}
                show={showSelectLocationModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Filter Location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <FormFieldLabel label='Select Category' />
                    {CATEGORIES && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={categoryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={CATEGORIES}
                        placeholder='Select Category'
                        isMulti={true}
                        onChange={e => {
                            setCategoryDropdown(e)
                        }} />} */}
                    <CommonInputFloatFull
                        type='text'
                        id='zipcode'
                        onChange={e => setZipCode(e.target.value)}
                        placeholder='Enter Zip Code'
                        value={zipcode} />
                    <FormFieldLabel label='Select Location' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border up-select-margin'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            setCityDropdown(null)
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border up-select-margin'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Apply'
                        onClick={() => { handleSelectLocation() }} />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default OfferList