const API_ENDPOINTS = {
    account: {
        login: 'user/login',
        userRegistration: 'user/registration',
        forgotPassword: 'user/forgotPassword',
        changePassword: 'user/changePassword',
        subscriptionList: 'subscription/list',
        operatorRegistration: 'user/operatorRegistration',
        operatorList: 'user/operatorList',
        operatorDetail: 'user/operatorDetail',
        updateOperatorLocation: 'user/updateOperatorLocation',
        updateNotificationToken: 'user/updateNotificationToken'
    },
    business: {
        addBusiness: 'business/addBusiness',
        listByLocation: 'business/listByLocation',
        userBusinessList: 'business/userBusinessList',
        details: 'business/details',
        updateDetails: 'business/updateDetails'
    },
    businessLocation: {
        addLocation: 'location/addLocation',
        list: 'location/list',
        details: 'location/details',
        updateLocation: 'location/updateLocation',
        updateLocationStatus: 'location/updateLocationStatus'
    },
    customer: {
        customerLogin: 'customer/login',
        customerList: 'customer/list',
        customerDetail: 'customer/detail',
        customerUpdateInfo: 'customer/updateInfo',
        addBankDetails: 'customer/bank/addDetails',
        updateBankDetails: 'customer/bank/updateDetails',
        searchOrAddCustomer: 'customer/searchOrAddCustomer'
    },
    location: {
        countryList: 'https://api.countrystatecity.in/v1/countries',
        stateList: 'states',
        cityList: 'cities'
    },
    offer: {
        addOffer: 'offer/add',
        offerDetail: 'offer/detail',
        offerList: 'offer/list',
        updateStatus: 'offer/updateStatus',
        updateDetails: 'offer/updateDetails'
    },
    cashback: {
        createRequest: 'cashbackRequest/submitRequest',
        requestList: 'cashbackRequest/list',
        updateStatus: 'cashbackRequest/updateStatus'
    },
    customerCashback: {
        addCustomerCashback: 'customerCashback/addCustomerCashback',
        customerCashbackList: 'customerCashback/list'
    },
    invoice: {
        createInvoice: 'invoice/createInvoice',
        invoiceList: 'invoice/invoiceList',
        invoiceDetail: 'invoice/invoiceDetail',
        updatePayment: 'invoice/updatePayment'
    }
}

export default API_ENDPOINTS