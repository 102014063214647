import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail, screenCaptureAnalytics
} from '../../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import {
    countryListRequest, stateListRequest, cityListRequest, displaySideBarAct
} from '../../../redux/user/user.action'
import { addBusinessRequest, addBusinessRequestStatus } from '../../../redux/business/business.action'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import { config } from '../../../config'
import Modal from 'react-bootstrap/Modal';
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";

const UpdateProfile = props => {
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [imagePreview, setImagePreview] = useState(null);
    const [address, setAddress] = useState('');
    const inputFile = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, user } = useSelector(state => state.user)
    const { addBusinessSuccess, addBusinessError, addBusinessReqStatus } = useSelector(state => state.business)
    let navigate = useNavigate();
    const [taxType, setTaxType] = useState('')
    const [taxNumber, setTaxNumber] = useState('')
    const [currencyDropdown, setCurrencyDropdown] = useState()

    useEffect(() => {
        const data = {
            screen: 'UPDATE_PROFILE',
            screenClass: 'UPDATE_PROFILE'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
    }, [])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (addBusinessReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast('Business details added successfully.')
            dispatch(displaySideBarAct(true))
            navigate(ROUTES.offerList, { replace: true });
        } else if (addBusinessReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addBusinessError.message)
        }

        if (addBusinessReqStatus?.length) {
            dispatch(addBusinessRequestStatus(''))
        }
    }, [addBusinessSuccess, addBusinessError, addBusinessReqStatus])

    const handleBusinessNameChange = e => {
        const businessName = e.target.value
        setBusinessName(businessName)
    }

    const handleImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
            setImagePreview(URL.createObjectURL(event.target.files[0]))
        } else {
            setImagePath(null)
            setImageName('')
            setImagePreview(null)
        }
    }

    const handleImageClick = () => {
        inputFile.current.click()
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const handleSubmitClick = () => {
        if (isEmpty(businessName)) {
            displayErrorToast('Business name cannot be blank', true)
        } else if (isNull(currencyDropdown)) {
            displayErrorToast('Currency not selected')
        } else if (isEmpty(address)) {
            displayErrorToast('Business location address cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Business country is not selected', true)
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Business state is not selected', true)
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Business city is not selected', true)
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('businessName', businessName)
            fd.append('userId', user.data.userDetails.userId)
            fd.append('email', user.data.userDetails.email)
            fd.append('productCode', user.data.userDetails.productCode)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            fd.append('address', address)
            fd.append('country', countryDropdown.name)
            fd.append('countryCode', countryDropdown.iso2)
            fd.append('state', stateDropdown.name)
            fd.append('stateCode', stateDropdown.iso2)
            fd.append('city', cityDropdown.name)
            fd.append('currencyLabel', currencyDropdown.code)
            fd.append('currencySymbol', currencyDropdown.symbol)
            fd.append('currencyRegion', currencyDropdown.region)
            dispatch(addBusinessRequest(fd))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Business Details' />
                <CommonFormBorder>
                    <FormFieldLabel className='up-bold-label' label='Business Information' />
                    <CommonInputFull
                        type="name"
                        id="businessName"
                        placeholder="Business Name"
                        value={businessName}
                        onChange={e => handleBusinessNameChange(e)}
                    />
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    <FormFieldLabel label='Select Currency' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={currencyDropdown}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setCurrencyDropdown(e)
                        }}
                    />}
                    <FormFieldLabel label='Address Information' />
                    <CommonInputFull
                        type="name"
                        id="address"
                        placeholder="Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                    />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow>
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>

                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default UpdateProfile