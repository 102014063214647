import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import './App.css';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import RouteGuard from './components/route-guard/route-guard.component'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from './utils/AppConstants'
import CustomerLogin from './pages/account/customer-login/customerLogin'
import VendorCreateAccount from './pages/account/vendor-create-account/vendorCreateAccount'
import VendorLogin from './pages/account/vendor-login/vendorLogin'
import OperatorList from './pages/operator/operator-list/operatorList'
import OperatorDetail from './pages/operator/operator-detail/operatorDetail'
import AddOperator from './pages/operator/add-operator/addOperator'
import { useSelector, useDispatch } from 'react-redux';
import CustomSidebar from './components/sidebar/sidebar.component';
import CashbackRequestList from './pages/cashback-request/cashback-request-list/cashbackRequestList'
import CustomerProfile from './pages/account/customer-profile/customerProfile'
import PrivacyPolicyPage from './pages/privacy-policy/privacyPolicyScreen'
import CookiePolicyScreen from './pages/cookie-policy/cookiePolicyScreen'
import UpdateProfile from './pages/account/update-profile/updateProfile'
import AddCustomerCashback from './pages/cashback-request/add-customer-cashback/addCustomerCashback'
import OfferList from './pages/offer/offer-list/offerList'
import AddOffer from './pages/offer/add-offer/addOffer'
import OfferDetails from './pages/offer/offer-detail/offerDetail.js'
import CustomerCashbackList from './pages/cashback-request/customer-cashback-list/customerCashbackList'
import InvoiceList from './pages/invoice/invoice-list/invoiceList'
import InvoiceDetail from './pages/invoice/invoice-detail/invoiceDetail'
import CreateInvoice from './pages/invoice/create-invoice/createInvoice'
import BusinessLandingPage from './pages/landing/business-landing-page/businessLandingPage'
import LandingPage from './pages/landing/landing-page/landingPage'
import VendorCashbackList from './pages/cashback-request/vendor-cashback-list/vendorCashbackList'
import AddBusinessScreen from './pages/account/business/add-business/addBusiness.js'
import BusinessListScreen from './pages/account/business/business-list/business-list.js'
import EditBusinessScreen from './pages/account/business/edit-business/editBusiness.js'
import LocationListScreen from './pages/account/location/location-list/locationList.js'
import AddLocationScreen from './pages/account/location/add-location/addLocation.js'
import EditLocationScreen from './pages/account/location/edit-location/editLocationScreen.js'
import { onMessageListener, messaging } from './fcmAnalytics'
import { getToken, onMessage } from "firebase/messaging";
import { updateUserNotificationTokenRequest, updateUserNotificationTokenRequestStatus, updateSelectedLocation } from './redux/user/user.action.js'
import { useEffect, useState } from 'react';
import Select, { components } from 'react-select'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserVendor } from './utils/Utils.js';

function App() {
  const { isLogin, displaySideBar, user,
    updateNotificationTokenSuccess, updateNotificationTokenError, updateNotificationTokenReqStatus
  } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const DEFAULT_LOCATION = { key: APP_CONSTANTS.DEFAULT_LOCATION_KEY, name: 'USA', img: require('./assets/flag/usa_flag.png') }
  const [countryLabel, setCountryLabel] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(DEFAULT_LOCATION)
  const LOCATION_LIST = [
    { key: 'USA', name: 'USA', img: require('./assets/flag/usa_flag.png') },
    { key: 'CANADA', name: 'Canada', img: require('./assets/flag/canada_flag.png') },
    { key: 'INDIA', name: 'India', img: require('./assets/flag/india_flag.png') },
    { key: 'UAE', name: 'UAE', img: require('./assets/flag/uae_flag.png') },
    { key: 'AUSTRALIA', name: 'Australia', img: require('./assets/flag/australia_flag.png') },
    { key: 'PAKISTAN', name: 'Pakistan', img: require('./assets/flag/pakistan_flag.png') },
    { key: 'OMAN', name: 'Oman', img: require('./assets/flag/oman_flag.png') }
  ]

  useEffect(() => {
    setSelectedLocation({
      value: DEFAULT_LOCATION.key,
      label: DEFAULT_LOCATION.name,
      data: DEFAULT_LOCATION,
    })
    dispatch(updateSelectedLocation(DEFAULT_LOCATION))
  }, [])

  useEffect(() => {
    if (updateNotificationTokenReqStatus?.length) {
      dispatch(updateUserNotificationTokenRequestStatus(''))
    }
  }, [updateNotificationTokenSuccess, updateNotificationTokenError, updateNotificationTokenReqStatus])

  useEffect(() => {
    if (isLogin === true) {
      if (isLoggedInUserCustomer(user) || isLoggedInUserVendor(user)) {
        fetchTokenFun()
      }
    }
  }, [isLogin])

  const fetchTokenFun = () => {
    getToken(messaging, { vapidKey: 'BIspxxbUJ0gzkHoh-2EM2L23Q5WvQP8wtWxm1Fxu9966unHL6cRBek3IHNvCMK9rMBSJl6p3GEUMUYiRAHAngso' })
      .then((currentToken) => {
        if (currentToken) {
          // localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, currentToken)
          sendFcmTokenToServer(currentToken)
        } else {
          displayErrorToast('Require permission to receive notification')
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      })
  }

  const sendFcmTokenToServer = (currentToken) => {
    const data = {
      notificationToken: currentToken
    }
    if (isLoggedInUserCustomer(user)) {
      data.customerId = user.data.userDetails.customerId
      data.userType = APP_CONSTANTS.USER_ROLE_TYPE.CUSTOMER
    } else if (isLoggedInUserVendor(user)) {
      data.userId = user.data.userDetails.userId
      data.userType = APP_CONSTANTS.USER_ROLE_TYPE.VENDOR
    }
    dispatch(updateUserNotificationTokenRequest(data))
  }

  onMessage(messaging, (payload) => {
    console.log('message received', payload);
    if (!isEmpty(payload.data) && !isEmpty(payload.data.message)) {
      displaySuccessToast(payload.data.message)
    }
  })

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className='select-row-container'>
          <div className='select-img-container'>
            <img src={props.data.data.img} alt={props.data.name} className='img' />
          </div>
          {props.data.data.name}
        </div>
      </components.Option>
    )
  };

  const SingleValue = ({ children, ...props }) => {
    console.log(props);
    return (
      <components.SingleValue {...props}>
        <div className='select-row-container'>
          <div className='select-img-container'>
            <img src={props.data.data.img} alt={props.data.name} className='img' />
          </div>
          {children}
        </div>
      </components.SingleValue>
    )
  };

  const handleChange = (selectedOption) => {
    setSelectedLocation(selectedOption.data);
    dispatch(updateSelectedLocation(selectedOption.data))
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: isLogin ? 'row' : 'column', height: isLogin ? '100vh' : '100vh', overflow: isLogin ? 'auto' : 'hidden' }}>
        {!isLogin && isEmpty(user) && <div className='select-container-parent'>
          <div className='select-container'>
            <Select
              options={LOCATION_LIST.map((item) => ({
                value: item.key,
                label: item.name,
                data: item,
              }))}
              onChange={handleChange}
              placeholder="Select Country"
              components={{ Option, SingleValue }}
              defaultValue={{
                value: DEFAULT_LOCATION.key,
                label: DEFAULT_LOCATION.name,
                data: DEFAULT_LOCATION,
              }}
              isSearchable={false}
              isMulti={false}
              autoFocus={false}
            />
          </div>
        </div>}
        {isLogin && user && displaySideBar === true && <CustomSidebar />}
        <div className="content-area-full">
          <Routes>
            {/* <Route path={ROUTES.home} element={<OfferList />} /> */}
            <Route path={ROUTES.home} element={<LandingPage />} />
            <Route path={ROUTES.business} element={<BusinessLandingPage />} />
            <Route path={ROUTES.offerList} element={<OfferList />} />
            <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
            <Route path={ROUTES.cookiePolicy} element={<CookiePolicyScreen />} />
            <Route path={ROUTES.customerLogin} element={<CustomerLogin />} />
            <Route path={ROUTES.vendorCreateAccount} element={<VendorCreateAccount />} />
            <Route path={ROUTES.vendorLogin} element={<VendorLogin />} />
            <Route path={ROUTES.offerDetail} element={
              <RouteGuard>
                <OfferDetails />
              </RouteGuard>
            } />
            <Route path={ROUTES.updateProfile} element={
              <RouteGuard>
                <UpdateProfile />
              </RouteGuard>
            } />
            <Route path={ROUTES.addBusiness} element={
              <RouteGuard>
                <AddBusinessScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.businessList} element={
              <RouteGuard>
                <BusinessListScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.businessDetail} element={
              <RouteGuard>
                <EditBusinessScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.locationList} element={
              <RouteGuard>
                <LocationListScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.addLocation} element={
              <RouteGuard>
                <AddLocationScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.editLocation} element={
              <RouteGuard>
                <EditLocationScreen />
              </RouteGuard>
            } />
            <Route path={ROUTES.addOffer} element={
              <RouteGuard>
                <AddOffer />
              </RouteGuard>
            } />
            <Route path={ROUTES.addCustomerCashback} element={
              <RouteGuard>
                <AddCustomerCashback />
              </RouteGuard>
            } />
            <Route path={ROUTES.operatorList} element={
              <RouteGuard>
                <OperatorList />
              </RouteGuard>
            } />
            <Route path={ROUTES.operatorRegister} element={
              <RouteGuard>
                <AddOperator />
              </RouteGuard>
            } />
            <Route path={ROUTES.operatorDetail} element={
              <RouteGuard>
                <OperatorDetail />
              </RouteGuard>
            } />
            <Route path={ROUTES.cashbackRequestList} element={
              <RouteGuard>
                <CashbackRequestList />
              </RouteGuard>
            } />
            <Route path={ROUTES.customerProfile} element={
              <RouteGuard>
                <CustomerProfile />
              </RouteGuard>
            } />
            <Route path={ROUTES.customerCashbackList} element={
              <RouteGuard>
                <CustomerCashbackList />
              </RouteGuard>
            } />
            <Route path={ROUTES.invoiceList} element={
              <RouteGuard>
                <InvoiceList />
              </RouteGuard>
            } />
            <Route path={ROUTES.invoiceDetail} element={
              <RouteGuard>
                <InvoiceDetail />
              </RouteGuard>
            } />
            <Route path={ROUTES.createInvoice} element={
              <RouteGuard>
                <CreateInvoice />
              </RouteGuard>
            } />
            <Route path={ROUTES.vendorCashbackList} element={
              <RouteGuard>
                <VendorCashbackList />
              </RouteGuard>
            } />
          </Routes>
        </div>
      </div>

    </>
  );
}

export default App;
